/* Global CSS */
html,body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  position: relative;
  background-color: #fff;
}

* {
  box-sizing: border-box;
}


